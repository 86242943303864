<template>
  <Modal ref="modal">
    <ModalItem :label="$locale.words['newa'] + ' ' + lowercase($locale.words['subscriptions'])" @click.native="type('new')" />
    <ModalItem :label="$locale.words['subscriptions'] + ' ' + lowercase(lang['expired'])" @click.native="type('expired')" />
    <ModalItem :label="$locale.words['subscriptions'] + ' ' + lowercase(lang['cancelled'])" @click.native="type('cancelled')" />
  </Modal>
</template>

<script>
import Modal from "../widgets/Modal.vue";
import ModalItem from "../widgets/ModalItem.vue";
export default {
  components: { Modal, ModalItem },
  methods: {
    open: function() {
      this.$refs.modal.open();
    },
    close: function() {
      this.$refs.modal.close();
    },
    type: function(type) {
      this.close();
      this.$emit("type", type);
    },
  },
  computed: {
    lang: function() {
      return this.$locale.statistics;
    },
  },
};
</script>
